import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useAuthContext } from "../../Context/AuthContex/AuthContex";
import { Button } from "react-bootstrap";
import { destroyCookie } from "nookies";
import {
  AUTH_USER_FAILED,
  AUTH_VISITOR_USER_FAILED,
} from "../../Helpers/Constant";
import Dropdown from "react-bootstrap/Dropdown";
import { VisitorAuthContext } from "../../Context/AuthContex/VisitorAuthContext";
const Header = () => {
  const { user, dispatch: authDispatch } = useAuthContext();
  const { visitorUser, dispatch: VisitorAuthDispatch } = VisitorAuthContext();

  const handleLogout = () => {
    destroyCookie(null, "haab_fair");
    authDispatch?.({
      type: AUTH_USER_FAILED,
    });
    VisitorAuthDispatch?.({
      type: AUTH_VISITOR_USER_FAILED,
    });
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        style={{ backgroundColor: "#fff" }}
      >
        <Container fluid className="custom-container">
          {/* <a href="https://bttf.net.bd/"> */}
          <Link to="/">
            <img
              src="/assets/logo.jpg"
              alt=""
              width={70}
              height={70}
              style={{ objectFit: "contain" }}
            />
          </Link>
          {/* </a> */}

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav ">
            <Nav className="ms-auto">
              <div className="header-navigation">
                <ul className="main-menu">
                  <li className="active-menu">
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/">
                      About
                      <MdKeyboardArrowDown size={20} />
                    </Link>
                    <ul className="sub-menu">
                      {/* <li>
                        <Link to="#">About BITTE</Link>
                      </li> */}
                      <li>
                        <a
                          href="https://haabbd.com/site/mission_vission"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          About HAAB
                        </a>
                      </li>
                      <li>
                        <Link to="#">Exhibition</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="#">
                      Event
                      <MdKeyboardArrowDown size={20} />
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/event-list">Event List</Link>
                      </li>
                      <li>
                        <Link to="#">Event Matrix</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="#">
                      Galleries
                      <MdKeyboardArrowDown size={20} />
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="#">Photo Gallery</Link>
                      </li>
                      <li>
                        <Link to="#">Video Gallery</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="#">
                      Media Room
                      <MdKeyboardArrowDown size={20} />
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="#">Print Media News</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    {/* <a
                      href=""
                      // href="https://bttf.net.bd/exhibitor.php"
                      target="_blank"
                      rel="noopener noreferrer"
                    > */}
                    {/* </a> */}
                  </li>
                  <li className="change-color">
                    <Link to="#">Event Mgt 2024</Link>
                  </li>

                  {user?.user_fair_member_id || visitorUser?.visitor_id ? (
                    ""
                  ) : (
                    <li className="change-color">
                      <Link to="/book-your-space">Book Your Space</Link>
                    </li>
                  )}
                </ul>
              </div>
            </Nav>
            <Nav className="ms-auto">
              {visitorUser?.visitor_id || user?.user_fair_member_id ? (
                <>
                  <Link
                    to={
                      visitorUser?.visitor_id ? "/visitor/profile" : "/profile"
                    }
                    className="text-white text-decoration-none"
                  >
                    <Button className="btn btn-3 btn-info me-2">Profile</Button>
                  </Link>

                  <Button
                    onClick={handleLogout}
                    className="btn btn-3 btn-primary"
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <>
                  <div className="header-btn  pe-3">
                    <Link
                      to="/visitor-registration"
                      className="btn btn-3 btn-primary"
                    >
                      Buyer/Visitor Registration
                    </Link>
                  </div>
                  <div className="header-btn st-2 mt-2 mt-md-0 ">
                    <Dropdown className="pe-3">
                      <Dropdown.Toggle
                        className="btn-info "
                        id="dropdown-basic"
                      >
                        Login
                      </Dropdown.Toggle>

                      <Dropdown.Menu align="start" className="ps-3">
                        <div className="pb-2">
                          <Link
                            to="/login"
                            className="text-decoration-none text-black "
                          >
                            Exhibitor login
                          </Link>
                        </div>

                        <div>
                          <Link
                            to="/visitor-login"
                            className="text-decoration-none text-black"
                          >
                            Buyer/Visitor login
                          </Link>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
