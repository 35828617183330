// export const BaseUrl: string = "https://bttf-server.toab.services";
// export const BaseUrl: string = 'http://192.168.0.237:5005';

// export const BaseUrl: string = "http://192.168.0.236:5000";
export const BaseUrl: string = "https://haab.services";

// export const imgUrl: string =
//   'https://toab.blob.core.windows.net/container1/uploads';
export const imgUrl: string =
  "https://m360ict-ecommerce.s3.ap-south-1.amazonaws.com/haab-storage";

//======== Auth context helper ========//
export const SET_SIGNUP_DATA = "SET_SIGNUP_DATA";
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const AUTH_USER_FAILED = "AUTH_USER_FAILED";
export const USER_PROFILE_UPDATE = "USER_PROFILE_UPDATE";
export const VISITOR_PROFILE_IMAGE_UPDATE = "VISITOR_PROFILE_IMAGE_UPDATE";
//======== visitor Auth context helper ========//

export const AUTH_VISITOR_USER_SUCCESS = "AUTH_VISITOR_USER_SUCCESS";
export const AUTH_VISITOR_USER_FAILED = "AUTH_VISITOR_USER_FAILED";
